import { graphql } from "../graphql";
import mutation from "./mutation.gql";
import { RepoErrorEnum } from "../repo-error.enum";

export async function updateAreasOnOutlet(
  areaIds: Array<string>,
  outletId: string,
  minBasket: number,
  deliveryFee: number
): Promise<boolean> {
  try {
    const response = await graphql.mutate({
      mutation,
      variables: {
        areaIds,
        outletId,
        minBasket,
        deliveryFee,
      },
    });

    return response.data.updateAreaCoverages;
  } catch {
    throw Error(RepoErrorEnum.NETWORK_ERROR);
  }
}
