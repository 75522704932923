
























































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  getCurrentInstance,
  ref,
  watch,
  reactive,
  computed,
} from "@vue/composition-api";
import { AtomLoading, MolPagination } from "@/v2/new-design-system";
import { DeliveryAreasAddModal } from "../add-modal";
import { DeliveryAreasListing } from "../listing";
import { deliveryAreasSetState } from "../state";
import { deliveryAreasTrack } from "../track";
import { IDeliveryAreasArea, IDeliveryAreasSort } from "../types";
import {
  fetchAreasByBusiness,
  IFetchAreasByBusiness,
} from "@/v2/repo/fetch-areas-by-businesses";
import {
  fetchOutletCoveredAreas,
  IFetchOutletCoveredAreasResponse,
} from "@/v2/repo/outlet/fetch-outlet-covered-areas";
import { useHelpers } from "@/v2/composable/use-helpers";
import { EventEnum } from "@/v2/enum";

const css = bemBuilder("delivery-areas-main");

export default defineComponent({
  name: "DeliveryAreasMain",
  components: {
    AtomLoading,
    MolPagination,
    DeliveryAreasListing,
    DeliveryAreasAddModal,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(true);
    const isLoadingAreas = ref(false);
    const displayAddModal = ref(false);

    const searchQuery = ref("");

    const sort = reactive<IDeliveryAreasSort>({
      field: "areaName",
      direction: "ASC",
    });

    const attachedAreas = ref<IDeliveryAreasArea[]>([]);
    const pagination = ref<IFetchOutletCoveredAreasResponse["pagination"]>();

    const areasMapped = ref<IFetchAreasByBusiness[]>([]);
    const hasAreasMappedOnFirstLoad = ref(false);
    const hasListingAreas = computed(() => areasMapped.value.length > 0);

    async function loadAttachedAreas({
      page = 1,
      showLoading = false,
      fetchAreasMapped = false,
      checkAttachedAreas = false,
    }) {
      if (showLoading) {
        isLoadingAreas.value = true;
      }

      if (fetchAreasMapped) {
        areasMapped.value = await fetchAreasByBusiness(props.businessId);
      }

      const res = await fetchOutletCoveredAreas({
        businessId: props.businessId,
        outletId: props.outletId,
        areaName: searchQuery.value,
        page: page,
        sort: {
          field: sort.field,
          direction: sort.direction,
        },
      });

      pagination.value = res.pagination;
      attachedAreas.value = res.data.map((area) => ({
        ...area,
        uniqueId: area.id,
      }));

      if (checkAttachedAreas) {
        hasAreasMappedOnFirstLoad.value = attachedAreas.value.length > 0;
      }

      deliveryAreasSetState("totalAttachedAreas", res.pagination.totalRecords);
      isLoadingAreas.value = false;
    }

    const currencyCode = computed(
      () => useHelpers().getCurrentBusiness(props.businessId).currency?.code
    );

    watch(
      [() => props.businessId, () => props.outletId],
      async () => {
        await updateAreas();
      },
      { immediate: true }
    );

    async function updateAreas() {
      await loadAttachedAreas({
        showLoading: true,
        fetchAreasMapped: true,
        checkAttachedAreas: true,
      });
      isLoading.value = false;
    }

    const vm = getCurrentInstance();

    function onDisplayAddModal() {
      deliveryAreasTrack({
        // @ts-ignore
        analytics: vm?.proxy.$analytics,
        eventName: EventEnum.DELIVERY_AREAS_START,
        businessId: props.businessId,
        outletId: props.outletId,
      });
      displayAddModal.value = true;
    }

    function onSearch(query: string) {
      searchQuery.value = query;
      loadAttachedAreas({ showLoading: true });
    }

    function onSort(
      field: IDeliveryAreasSort["field"],
      direction: IDeliveryAreasSort["direction"]
    ) {
      if (
        (!field && !sort.field) ||
        (field === sort.field && direction === sort.direction)
      ) {
        return;
      }

      sort.field = field;
      sort.direction = direction;

      loadAttachedAreas({});
    }

    return {
      css,
      onSort,
      onSearch,
      isLoading,
      isLoadingAreas,
      displayAddModal,
      attachedAreas,
      currencyCode,
      areasMapped,
      hasListingAreas,
      pagination,
      loadAttachedAreas,
      onDisplayAddModal,
      hasAreasMappedOnFirstLoad,
    };
  },
});
