import { EventCategoryEnum } from "@/v2/enum/events";
import {
  analyticsEventCommonData,
  ITrackEvent,
  trackEvent,
} from "@/v2/util/analytics/trackEvent";

interface IDeliveryAreasTrack {
  businessId: string;
  outletId: string;
}

export function deliveryAreasTrack({
  analytics,
  eventName,
  additionalData,
  businessId,
  outletId,
}: ITrackEvent & IDeliveryAreasTrack): void {
  trackEvent({
    eventCategory: EventCategoryEnum.DELIVERY_AREAS,
    analytics,
    eventName,
    additionalData: {
      ...analyticsEventCommonData([
        ["business", businessId],
        ["outlet", outletId],
      ]),
      ...additionalData,
    },
  });
}
