























































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, ref } from "@vue/composition-api";
import {
  AtomModal,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomButton,
  AtomButtonTypeEnum,
  AtomButtonSizeEnum,
  MolMultiselect,
  MolFormControl,
} from "@/v2/new-design-system";
import { t } from "@/i18n";

const css = bemBuilder("outlet-modal-link-branch");

interface IBranch {
  name: string;
  branchId: string;
  linkedOutletId: string;
}

export default defineComponent({
  name: "OutletModalLinkBranch",
  components: {
    AtomModal,
    AtomText,
    AtomButton,
    MolMultiselect,
    MolFormControl,
  },
  props: {
    branches: {
      type: Array as PropType<Array<IBranch>>,
      default: () => [],
    },
    onSelect: {
      type: Function as PropType<(branchId: string) => void>,
      required: true,
    },
    onClose: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const selectedBranch = ref<IBranch>({
      name: "",
      branchId: "",
      linkedOutletId: "",
    });

    function onConnectBranch() {
      props.onSelect(selectedBranch.value.branchId);
    }

    return {
      t,
      css,
      onConnectBranch,
      selectedBranch,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonTypeEnum,
      AtomButtonSizeEnum,
    };
  },
});
