var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.css()},[(_vm.isLoading)?_c('AtomLoading',{attrs:{"floating":"","inline":""}}):[_c('DeliveryAreasListing',{attrs:{"areas":_vm.attachedAreas,"loading-areas":_vm.isLoadingAreas,"outlet-id":_vm.outletId,"business-id":_vm.businessId,"currency-code":_vm.currencyCode,"total-records":_vm.pagination.totalRecords,"has-listing-areas":_vm.hasListingAreas,"has-areas-listed-first-load":_vm.hasAreasMappedOnFirstLoad,"on-search":_vm.onSearch,"on-sort":_vm.onSort,"on-display-add-modal":_vm.onDisplayAddModal,"on-delete-area":function () { return _vm.loadAttachedAreas({
            page: _vm.pagination.currentPage,
            fetchAreasMapped: true,
          }); },"on-update-area":function () { return _vm.loadAttachedAreas({ page: _vm.pagination.currentPage }); }}}),(_vm.pagination && _vm.pagination.totalRecords > 0)?_c('MolPagination',{class:_vm.css('pagination'),attrs:{"current-page":_vm.pagination.currentPage,"last-page":_vm.pagination.lastPage,"per-page":_vm.pagination.perPage,"total":_vm.pagination.totalRecords,"on-navigate":function (page) { return _vm.loadAttachedAreas({ page: page }); }}}):_vm._e(),(_vm.displayAddModal)?_c('DeliveryAreasAddModal',{attrs:{"business-id":_vm.businessId,"outlet-id":_vm.outletId,"currency-code":_vm.currencyCode,"areas":_vm.areasMapped,"on-add-areas":function () { return _vm.loadAttachedAreas({
            showLoading: true,
            fetchAreasMapped: true,
            checkAttachedAreas: true,
          }); },"on-close":function () { return (_vm.displayAddModal = false); }}}):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }