


































































import { Toast } from "@/design-system";
import { tc, t } from "@/i18n";
import { CurrencyCodeEnum, EventEnum } from "@/v2/enum";
import {
  AtomButton,
  AtomButtonTypeEnum,
  AtomModal,
  AtomText,
  AtomLoading,
  AtomTextTypeEnum,
  IOrgCollapsableSelectListItem,
  IOrgCollapsableSelectListProps,
  MolSearchBox,
  OrgCollapsableSelectList,
  OrgFormMoneyInput,
} from "@/v2/new-design-system";
import { createAreasOnOutlet } from "@/v2/repo/create-areas-on-outlet";
import { IFetchAreasByBusiness } from "@/v2/repo/fetch-areas-by-businesses";
import { bemBuilder } from "@/v2/util/bem-builder";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import {
  defineComponent,
  getCurrentInstance,
  PropType,
  ref,
  computed,
} from "@vue/composition-api";
import { deliveryAreasTrack } from "../track";

const css = bemBuilder("delivery-areas-add-modal");

export default defineComponent({
  name: "DeliveryAreasAddModal",
  components: {
    AtomModal,
    AtomLoading,
    AtomText,
    OrgFormMoneyInput,
    OrgCollapsableSelectList,
    AtomButton,
    MolSearchBox,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
    currencyCode: {
      type: String as PropType<CurrencyCodeEnum>,
      required: true,
    },
    areas: {
      type: Array as PropType<IFetchAreasByBusiness[]>,
      default: () => [],
    },
    onAddAreas: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onClose: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const selectedAreaIds = ref<Array<string>>([]);
    const minBasket = ref(0);
    const deliveryFee = ref(0);
    const searchFilter = ref("");
    const loading = ref(false);

    const listData = computed<IOrgCollapsableSelectListProps["data"]>(() => {
      const uniqueCities = new Set(props.areas.map((area) => area.city));

      return Array.from(uniqueCities)
        .reduce((citiesTotal, city) => {
          // Group items by city
          const items = props.areas.reduce((itemsTotal, area) => {
            if (area.city === city) {
              let disabledText = "";

              if (area.coveredByOutlet) {
                disabledText =
                  props.outletId === area.coveredByOutlet
                    ? t("module.delivery_areas.add_modal.already_added")
                    : t(
                        "module.delivery_areas.add_modal.covered_by_another_outlet"
                      );
              }

              const item: IOrgCollapsableSelectListItem = {
                id: area.id,
                name: area.name,
                disabledText,
                selected: false,
              };
              itemsTotal.push(item);
            }
            return itemsTotal;
          }, [] as Array<IOrgCollapsableSelectListItem>);

          citiesTotal.push({
            name: city,
            items: items.sort((a, b) => {
              // Sort items alphabetically
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            }),
          });

          return citiesTotal;
        }, [] as IOrgCollapsableSelectListProps["data"])
        .sort((a, b) => {
          // Sort categories alphabetically
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
    });

    const vm = getCurrentInstance();

    async function save() {
      loading.value = true;

      try {
        await createAreasOnOutlet(
          props.outletId,
          selectedAreaIds.value,
          minBasket.value,
          deliveryFee.value
        );

        props.onAddAreas();

        new Toast().create({
          type: "success",
          title: t("module.delivery_areas.toast.added"),
          text: tc(
            "module.delivery_areas.toast.added_count",
            selectedAreaIds.value.length
          ),
        });
        deliveryAreasTrack({
          // @ts-ignore
          analytics: vm?.proxy.$analytics,
          eventName: EventEnum.DELIVERY_AREA_CREATED,
          businessId: props.businessId,
          outletId: props.outletId,
          additionalData: {
            delivery_area_type: "predefined",
          },
        });
      } catch {
        generalErrorToast();
      } finally {
        loading.value = false;
        props.onClose();
      }
    }

    function trackAreaNotFound() {
      deliveryAreasTrack({
        // @ts-ignore
        analytics: vm?.proxy.$analytics,
        eventName: EventEnum.DELIVERY_AREAS_NOT_FOUND,
        businessId: props.businessId,
        outletId: props.outletId,
      });
    }

    return {
      t,
      tc,
      css,
      listData,
      searchFilter,
      selectedAreaIds,
      minBasket,
      loading,
      trackAreaNotFound,
      deliveryFee,
      save,
      AtomTextTypeEnum,
      AtomButtonTypeEnum,
    };
  },
});
