

















































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import {
  AtomCard,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomLink,
  AtomLinkTypeEnum,
  AtomLinkSizeEnum,
  AtomButton,
  AtomGraphic,
} from "@/v2/new-design-system";
import { t } from "@/i18n";

const css = bemBuilder("outlet-integration-box");

export default defineComponent({
  name: "OutletIntegrationBox",
  components: {
    AtomCard,
    AtomText,
    AtomLink,
    AtomButton,
    AtomGraphic,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: "",
    },
    logoName: {
      type: String,
      default: "",
    },
    isConnected: {
      type: Boolean,
      default: false,
    },
    buttonLabel: {
      type: String,
      required: true,
    },
    disabledButton: {
      type: Boolean,
      default: false,
    },
    linkRoute: {
      type: String,
      default: "",
    },
    linkLabel: {
      type: String,
      default: "",
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    return {
      t,
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomLinkTypeEnum,
      AtomLinkSizeEnum,
    };
  },
});
