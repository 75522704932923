var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.css()},[(!_vm.hasAttachedAreas)?_c('div',{class:_vm.css('icon'),attrs:{"data-test":"icon"}},[_c('AtomIcon',{attrs:{"name":"bicycle","color":"var(--color-chatfood)","size":32}})],1):_vm._e(),_c('div',{class:[
      _vm.css('content'),
      ( _obj = {}, _obj[_vm.css('content', 'empty')] = !_vm.hasAttachedAreas, _obj ) ]},[_c('OrgHeaderInfo',{attrs:{"heading":_vm.t('module.delivery_areas.header.heading'),"sub-heading":_vm.t('module.delivery_areas.header.sub_heading')},scopedSlots:_vm._u([{key:"links",fn:function(){return [_c('MolGuideLink',{attrs:{"article":_vm.MolGuideLinkArticleEnum.HOW_TO_MANAGE_DELIVERY_AREAS}}),_c('MolModalGuideLink',{attrs:{"video":_vm.MolModalGuideLinkVideoEnum.HOW_TO_MANAGE_DELIVERY_AREAS}})]},proxy:true}])}),_c('div',{class:_vm.css('cta')},[_c('AtomButton',{class:_vm.css('cta-btn'),attrs:{"data-test":"cta","on-click":_vm.onAdd,"size":_vm.AtomButtonSizeEnum.LARGE}},[_c('AtomIcon',{attrs:{"name":"add","color":"var(--color-pure-white)","size":20}}),_c('span',[_vm._v(_vm._s(_vm.t("module.delivery_areas.header.add_areas")))])],1),(_vm.showSubmenu)?_c('ul',{class:_vm.css('cta-submenu'),attrs:{"data-test":"submenu"}},[_c('li',[_c('a',{class:_vm.css('cta-submenu-link'),attrs:{"href":"","data-test":"cta-open-modal"},on:{"click":function($event){$event.preventDefault();return _vm.openModal.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.t("module.delivery_areas.header.cta_mapped_area"))+" ")])]),_c('li',[_c('router-link',{class:_vm.css('cta-submenu-link'),attrs:{"data-test":"cta-open-map","to":{
              name: 'outlets.delivery-areas.create',
              params: {
                businessId: _vm.businessId,
                outletId: _vm.outletId,
              },
            }}},[_vm._v(" "+_vm._s(_vm.t("module.delivery_areas.header.cta_map"))+" ")])],1)]):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }