












































































/* global google */
import { t } from "@/i18n";
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
} from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import { useGoogleMaps } from "@/v2/util/maps/google-maps";
import {
  AtomButton,
  AtomButtonTypeEnum,
  AtomInput,
  AtomMap,
  AtomMapMarker,
  AtomModal,
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
  IAtomMapProps,
  MolMapAutocomplete,
  IMolMapAutocompletePrediction,
} from "@/v2/new-design-system";
import { fetchOutletAddress } from "@/v2/repo/fetch-outlet-address";
import { updateOutletAddress } from "@/v2/repo/update-outlet-address";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { Toast } from "@/design-system";

interface IAddress {
  location: string;
  area: string;
  addressId: string;
}

interface IOutletCoordinates {
  lat?: number;
  lng?: number;
}

const css = bemBuilder("delivery-areas-geo-confirm-outlet-address");

const mapSettings = {
  centerMap: false,
  options: {
    disableDefaultUI: true,
    gestureHandling: "greedy",
  },
};

export default defineComponent({
  name: "DeliveryAreasGeoConfirmOutletAddress",
  components: {
    AtomModal,
    AtomText,
    AtomMap,
    AtomMapMarker,
    AtomInput,
    AtomButton,
    MolMapAutocomplete,
  },
  props: {
    outletId: {
      type: String,
      required: true,
    },
    apiKey: {
      type: String,
      required: true,
    },
    onCancel: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onConfirm: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
  },
  setup(props) {
    const gMaps = ref<typeof google.maps>();
    const gGeocoder = ref<google.maps.Geocoder>();
    const country = ref("");
    const autocompleteInput = ref("");
    const outletPosition = ref<IAtomMapProps["initialPosition"]>({});
    const outletCoordinates = ref<IOutletCoordinates>({});
    const address = ref<IAddress>({ location: "", area: "", addressId: "" });

    const disableCTA = computed(() => {
      const { lat, lng } = outletCoordinates.value;
      return !lat || !lng;
    });

    const useGeocoder = (
      location: google.maps.GeocoderRequest,
      successCallback: (position: google.maps.GeocoderResult) => void,
      failedCallback?: (status: google.maps.GeocoderStatus) => void
    ) => {
      gGeocoder.value?.geocode(
        location,
        (
          results: google.maps.GeocoderResult[],
          status: google.maps.GeocoderStatus
        ) => {
          if (status === gMaps.value?.GeocoderStatus.OK && results[0]) {
            successCallback(results[0]);
          }
          failedCallback && failedCallback(status);
        }
      );
    };

    const setOutletPosition = (position: google.maps.GeocoderResult) => {
      const {
        place_id,
        geometry: { location },
      } = position;

      outletPosition.value = {
        placeId: place_id,
      };

      outletCoordinates.value = {
        lat: location.lat(),
        lng: location.lng(),
      };
    };

    const onSelect = (prediction: IMolMapAutocompletePrediction) => {
      const { placeId } = prediction;
      useGeocoder({ placeId }, setOutletPosition);
    };

    const onMoveMarker = (location: google.maps.LatLng) => {
      const coordinates = {
        lat: location.lat(),
        lng: location.lng(),
      };

      useGeocoder({ location: coordinates }, (position) => {
        const { formatted_address } = position;
        autocompleteInput.value = formatted_address;
        outletCoordinates.value = coordinates;
      });
    };

    async function mountMap() {
      try {
        const { getMaps } = useGoogleMaps(props.apiKey);
        gMaps.value = await getMaps();
        gGeocoder.value = new gMaps.value.Geocoder();
      } catch {
        generalErrorToast();
      }
    }

    async function mountComponent() {
      await mountMap();

      try {
        const outlet = await fetchOutletAddress(props.outletId);

        const { location, area, id } = outlet?.address || {};
        address.value = { location, area: area?.name, addressId: id };
        country.value = outlet?.business?.country;

        useGeocoder(
          {
            address: address.value.location,
            componentRestrictions: {
              country: country.value,
            },
          },
          setOutletPosition,
          (status) => {
            if (status === gMaps.value?.GeocoderStatus.ZERO_RESULTS) {
              useGeocoder(
                {
                  address: country.value,
                },
                setOutletPosition
              );
            }
          }
        );
      } catch {
        generalErrorToast();
      }
    }

    onMounted(mountComponent);

    async function handleConfirm() {
      const { lat, lng } = outletCoordinates.value;

      if (!lat || !lng) return;

      let result = false;
      try {
        result = await updateOutletAddress({
          outletId: props.outletId,
          ...address.value,
          location:
            autocompleteInput.value || address.value.location || country.value,
          lat,
          lng,
        });
      } catch {
        new Toast().create({
          type: "error",
          title: t("label.oops"),
          text: t("message.general_failure"),
        });
      }

      if (result) {
        new Toast().create({
          type: "success",
          title: t("label.success"),
          text: t(
            "module.delivery_areas.confirm_outlet_address.success_message"
          ),
        });
        props.onConfirm();
      }
    }

    return {
      t,
      css,
      mapSettings,
      country,
      address,
      outletPosition,
      outletCoordinates,
      autocompleteInput,
      disableCTA,
      onSelect,
      onMoveMarker,
      handleConfirm,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonTypeEnum,
    };
  },
});
