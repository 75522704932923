


































































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, ref } from "@vue/composition-api";
import {
  AtomIcon,
  AtomButton,
  AtomButtonSizeEnum,
  MolGuideLink,
  MolModalGuideLink,
  MolGuideLinkArticleEnum,
  MolModalGuideLinkVideoEnum,
  OrgHeaderInfo,
} from "@/v2/new-design-system";
import { useRouter } from "@/router";
import { t } from "@/i18n";

const css = bemBuilder("delivery-areas-header");

export default defineComponent({
  name: "DeliveryAreasHeader",
  components: {
    AtomIcon,
    AtomButton,
    MolGuideLink,
    MolModalGuideLink,
    OrgHeaderInfo,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
    hasListingAreas: {
      type: Boolean,
      default: false,
    },
    hasAttachedAreas: {
      type: Boolean,
      default: false,
    },
    onDisplayAddModal: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const showSubmenu = ref(false);

    const router = useRouter();

    function onAdd() {
      if (props.hasListingAreas) {
        showSubmenu.value = !showSubmenu.value;
        return;
      }

      router.push({
        name: "outlets.delivery-areas.create",
        params: {
          businessId: props.businessId,
          outletId: props.outletId,
        },
      });
    }

    function openModal() {
      showSubmenu.value = false;
      props.onDisplayAddModal();
    }

    return {
      t,
      css,
      onAdd,
      openModal,
      showSubmenu,
      AtomButtonSizeEnum,
      MolGuideLinkArticleEnum,
      MolModalGuideLinkVideoEnum,
    };
  },
});
