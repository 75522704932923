import { graphql } from "../graphql";
import mutation from "./mutation.gql";
import { RepoErrorEnum } from "../repo-error.enum";

export async function createAreasOnOutlet(
  outletId: string,
  areaIds: Array<string>,
  minBasket: number,
  deliveryFee: number
): Promise<boolean> {
  try {
    const response = await graphql.mutate({
      mutation,
      variables: {
        outletId,
        areaIds,
        minBasket,
        deliveryFee,
      },
    });
    return response.data.createAreaCoverages;
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
}
