import { graphql } from "../graphql";
import query from "./query.gql";
import { RepoErrorEnum } from "../repo-error.enum";

export async function fetchAreasByBusiness(
  businessId: string
): IFetchAreasByBusinessResponse {
  try {
    const response = await graphql.query({
      query,
      variables: {
        businessId,
      },
    });

    return response.data.areas;
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
}

type IFetchAreasByBusinessResponse = Promise<Array<IFetchAreasByBusiness>>;

export interface IFetchAreasByBusiness {
  id: string;
  name: string;
  city: string;
  coveredByOutlet: string;
}
