






























import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  onMounted,
  ref,
  computed,
  onBeforeUnmount,
} from "@vue/composition-api";
import { AtomLoading, OrgHeaderInfo } from "@/v2/new-design-system";
import { OutletIntegrationBox } from "./integration-box";
import { OutletModalLinkBranch } from "./modal-link-branch";
import {
  listenFromBusiness,
  unlistenFromBusiness,
  IBusinessChannelEvent,
} from "@/v2/core/broadcasting";
import {
  fetchFoodicsBranches,
  IFetchFoodicsBranchesResponse,
} from "@/v2/repo/outlet/fetch-foodics-branches";
import { linkFoodicsBranch } from "@/v2/repo/outlet/link-foodics-branch";
import { Toast } from "@/design-system";
import { report } from "@chatfood/bug-reporter";
import { t } from "@/i18n";

const css = bemBuilder("outlet-dine-in-payments");

export default defineComponent({
  name: "OutletDineInPayments",
  components: {
    AtomLoading,
    OrgHeaderInfo,
    OutletIntegrationBox,
    OutletModalLinkBranch,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const isFoodicsConnected = ref(false);
    const isConnectingFoodics = ref(false);
    const showModalLinkBranch = ref(false);
    const foodicsBranches = ref<Array<IFetchFoodicsBranchesResponse>>([]);

    const foodicsTokenAcquired = "foodics.token-acquired";
    const foodicsBranchesSynced = "foodics.branches-synced";

    const foodicsUrl = computed(
      () => `${process.env.VUE_APP_API_URL}/foodics/${props.businessId}/connect`
    );

    const foodicsButtonLabel = computed(() =>
      isConnectingFoodics.value
        ? t("module.outlets.dine_in_payments.connecting")
        : t("module.outlets.dine_in_payments.foodics.button_label")
    );

    let foodicsConsolePopup: Window | null;

    function onConnectFoodics() {
      if (foodicsBranches.value.length) {
        showModalLinkBranch.value = true;
        return;
      }

      foodicsConsolePopup = window.open(
        foodicsUrl.value,
        "",
        "width=360, height=600"
      );
    }

    function handleTokenAcquired() {
      foodicsConsolePopup?.close();
      unlistenEvent(foodicsTokenAcquired, handleTokenAcquired);

      isConnectingFoodics.value = true;
      listenEvent(foodicsBranchesSynced, handleBranchesSynced);
    }

    async function handleBranchesSynced() {
      await checkFoodics();

      isConnectingFoodics.value = false;
      showModalLinkBranch.value = true;

      unlistenEvent(foodicsBranchesSynced, handleBranchesSynced);
    }

    function listenEvent(eventName: IBusinessChannelEvent, action: () => void) {
      listenFromBusiness(props.businessId, [eventName], action);
    }

    function unlistenEvent(
      eventName: IBusinessChannelEvent,
      action: () => void
    ) {
      unlistenFromBusiness(props.businessId, [eventName], action);
    }

    onMounted(async () => {
      isLoading.value = true;

      await checkFoodics();
      isLoading.value = false;

      if (!isFoodicsConnected.value) {
        listenEvent(foodicsTokenAcquired, handleTokenAcquired);
      }
    });

    async function checkFoodics() {
      try {
        foodicsBranches.value = await fetchFoodicsBranches({
          businessId: props.businessId,
        });

        if (foodicsBranches.value.length) {
          const hasBranchLinked = foodicsBranches.value.find(
            (obj: IFetchFoodicsBranchesResponse) =>
              obj.linkedOutletId === props.outletId
          );

          if (hasBranchLinked) {
            isFoodicsConnected.value = true;
            return;
          }
        }
      } catch (e) {
        report(e);
      }
    }

    onBeforeUnmount(() => {
      unlistenEvent(foodicsTokenAcquired, handleTokenAcquired);
      unlistenEvent(foodicsBranchesSynced, handleBranchesSynced);
    });

    async function onLinkBranchFoodics(branchId: string) {
      isConnectingFoodics.value = true;
      showModalLinkBranch.value = false;

      try {
        await linkFoodicsBranch({
          businessId: props.businessId,
          branchId,
          linkedOutletId: props.outletId,
        });

        isFoodicsConnected.value = true;

        new Toast().create({
          type: "success",
          text: t("module.outlets.dine_in_payments.toast_success_message"),
        });
      } catch (e) {
        report(e);
        new Toast().create({
          type: "error",
          text: t("module.outlets.dine_in_payments.toast_error_message"),
        });
      } finally {
        isConnectingFoodics.value = false;
      }
    }

    return {
      t,
      css,
      isLoading,
      foodicsUrl,
      foodicsBranches,
      onConnectFoodics,
      isFoodicsConnected,
      foodicsButtonLabel,
      isConnectingFoodics,
      showModalLinkBranch,
      onLinkBranchFoodics,
    };
  },
});
