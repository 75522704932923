import { IRepoErrors } from "../../errors";
import { http } from "../../http";

interface ILinkFoodicsBranchRequest {
  businessId: string;
  branchId: string;
  linkedOutletId: string;
}

export class LinkFoodicsBranchError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: LinkFoodicsBranch");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function linkFoodicsBranch(
  request: ILinkFoodicsBranchRequest
): Promise<boolean> {
  try {
    await http.post(
      `/foodics/${request.businessId}/branches/${request.branchId}/link`,
      {
        linked_outlet_id: request.linkedOutletId,
      }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new LinkFoodicsBranchError(error);
  }

  return true;
}
