interface Coordinates {
  x: number;
  y: number;
}

export const createLinesSegment = (
  coordinates: Array<[number, number]>
): Array<[Coordinates, Coordinates]> => {
  const lines: Array<[Coordinates, Coordinates]> = [];

  coordinates.forEach((_, index: number) => {
    const [x1, y1] = coordinates[index];
    const [x2, y2] = coordinates[index + 1] ?? coordinates[0];

    lines.push([
      { x: x1, y: y1 },
      { x: x2, y: y2 },
    ]);
  });

  return lines;
};

export const isSamePoint = (a: Coordinates, b: Coordinates): boolean => {
  if (!a || !b) return false;

  return a.x === b.x && a.y === b.y;
};

const verifySegment = (
  a: Coordinates,
  b: Coordinates,
  c: Coordinates
): boolean => {
  const x1 = b.x - a.x;
  const y1 = c.y - a.y;
  const x2 = c.x - a.x;
  const y2 = b.y - a.y;

  return y1 * x1 > y2 * x2;
};

export function polygonSelfIntersection(
  polygon: Array<[number, number]>
): boolean {
  if (!polygon.length) return false;

  const lines = createLinesSegment(polygon);

  return lines.some((line, index) => {
    const uniqueLines = lines.filter((_, i) => index !== i);
    return uniqueLines.some((currentLine) => {
      const [x1, y1] = line;
      const [x2, y2] = currentLine;

      if (
        isSamePoint(x1, x2) ||
        isSamePoint(x1, y2) ||
        isSamePoint(y1, x2) ||
        isSamePoint(y1, y2)
      ) {
        return false;
      }

      return (
        verifySegment(x1, x2, y2) != verifySegment(y1, x2, y2) &&
        verifySegment(x1, y1, x2) != verifySegment(x1, y1, y2)
      );
    });
  });
}
