import { graphql } from "../graphql";
import mutation from "./mutation.gql";
import { FetchResult } from "@apollo/client";
import { IRepoErrors } from "../errors";
import { graphqlExtractGraphqlErrors } from "@/v2/util/graphql-extract-graphql-errors";
import { graphqlRemapValidationErrors } from "@/v2/util/graphql-remap-validation-errors";

export interface ICreateAreaGeoOnOuletBody {
  outletId: string;
  areaName: string;
  minBasket: number;
  deliveryFee: number;
  polygon: Array<Array<number>>;
}

export async function createAreaGeoOnOulet(
  payload: ICreateAreaGeoOnOuletBody
): Promise<boolean> {
  let response: FetchResult<any, Record<string, any>, Record<string, any>>;
  const errors: IRepoErrors<ValidationErrors> = {};

  const formattedPolygon = payload.polygon.map(
    ([latitude, longitude]): [number, number] => ({
      // @ts-ignore
      latitude,
      longitude,
    })
  );

  formattedPolygon.push({
    // @ts-ignore
    latitude: payload.polygon[0][0],
    longitude: payload.polygon[0][1],
  });

  try {
    response = await graphql.mutate({
      mutation,
      variables: {
        outletId: payload.outletId,
        areaName: payload.areaName,
        minBasket: payload.minBasket,
        deliveryFee: payload.deliveryFee,
        location: {
          type: "POLYGON",
          coordinates: [formattedPolygon],
        },
      },
    });
  } catch (e) {
    errors.networkError = true;
    throw new CreateAreaGeoOnOuletError(errors);
  }

  if (response.errors) {
    errors.graphqlError = graphqlExtractGraphqlErrors(response);
    errors.validationError = graphqlRemapValidationErrors(response);

    throw new CreateAreaGeoOnOuletError(errors);
  }

  return Boolean(response.data.createAreaCoveragesWithCoordinates);
}

export class CreateAreaGeoOnOuletError extends Error {
  errors: IRepoErrors<ValidationErrors>;

  constructor(errors: IRepoErrors<ValidationErrors>) {
    super("Error in repo: createAreaGeoOnOulet");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

type ValidationErrors = {};
