import { stateBuilder } from "@/v2/util/state/state-builder";

const DeliveryAreasState = {
  moduleName: "DeliveryAreas",
  state: {
    totalAttachedAreas: 0 as number,
  },
};

const {
  initState: deliveryAreasInitState,
  setState: deliveryAreasSetState,
  getState: deliveryAreasGetState,
  action: deliveryAreasAction,
} = stateBuilder<typeof DeliveryAreasState>(DeliveryAreasState);

deliveryAreasInitState();

export {
  deliveryAreasInitState,
  deliveryAreasSetState,
  deliveryAreasGetState,
  deliveryAreasAction,
};
