import { http } from "../../http";
import { AxiosResponse } from "axios";
import { IRepoErrors } from "../../errors";
import {
  IFetchOutletCoveredAreasRequest,
  IFetchOutletCoveredAreasContract,
  IFetchOutletCoveredAreasResponse,
  IFetchOutletCoveredAreasBodyPayload,
} from "./types";

const sortKeyHandler = (
  sortBy?: IFetchOutletCoveredAreasRequest["sort"]
): IFetchOutletCoveredAreasBodyPayload["sort"] => {
  const isAsc = sortBy?.direction === "ASC";

  const mapFields: Record<string, IFetchOutletCoveredAreasBodyPayload["sort"]> =
    {
      status: isAsc ? "is_enabled" : "-is_enabled",
      areaName: isAsc ? "area_name" : "-area_name",
      minBasket: isAsc ? "min_basket" : "-min_basket",
      deliveryFee: isAsc ? "delivery_fee" : "-delivery_fee",
    };

  return sortBy ? mapFields[sortBy?.field] : undefined;
};

export class FetchOutletCoveredAreasError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchOutletCoveredAreas");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchOutletCoveredAreas(
  request: IFetchOutletCoveredAreasRequest
): Promise<IFetchOutletCoveredAreasResponse> {
  let response: AxiosResponse<IFetchOutletCoveredAreasContract>;

  let params: IFetchOutletCoveredAreasBodyPayload = {
    per_page: request.perPage ?? 15,
    page: request.page ?? 1,
    sort: sortKeyHandler(request.sort),
  };

  if (request.areaName) {
    params = {
      ...params,
      area_name: request.areaName,
    };
  }

  try {
    response = await http.get(
      `/api/v4/businesses/${request.businessId}/outlets/${request.outletId}/covered-areas`,
      { params }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchOutletCoveredAreasError(error);
  }

  const data = response.data.data;
  const meta = response.data.meta;

  return {
    data: data?.length
      ? data.map((obj) => ({
          id: obj.id,
          status: obj.is_enabled,
          areaName: obj.area,
          city: obj.city ?? "",
          minBasket: obj.min_basket,
          deliveryFee: obj.delivery_fee,
          isShared: obj.is_shared,
          hasPolygon: obj.has_polygon,
        }))
      : [],
    pagination: {
      currentPage: meta.current_page,
      lastPage: meta.last_page,
      perPage: meta.per_page,
      totalRecords: meta.total,
    },
  };
}
