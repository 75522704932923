import { graphql } from "../graphql";
import query from "./query.gql";
import { RepoErrorEnum } from "../repo-error.enum";

export async function fetchAreasGeoByBusiness(
  businessId: string
): IFetchAreasGeoByBusinessResponse {
  try {
    const response = await graphql.query({
      query: query,
      variables: {
        businessId,
      },
    });

    if (response?.data?.areas) {
      // process location

      return response.data.areas.map((area: any) => {
        let coordinates: Array<[number, number]> = [];

        if (area.location) {
          coordinates = area.location.coordinates[0].map(
            (coordinatePair: any) => [
              coordinatePair.latitude,
              coordinatePair.longitude,
            ]
          );
        }

        return {
          id: area.areaId,
          areaName: area.areaName,
          outletId: area.outletId,
          status: area.status,
          minBasket: area.minBasket,
          deliveryFee: area.deliveryFee,
          location: coordinates,
        };
      });
    }

    return [];
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
}

export interface IAreasGeoByBusiness {
  id: string;
  areaName: string;
  outletId: string;
  status: boolean;
  minBasket: number;
  deliveryFee: number;
  location: Array<[number, number]>;
}

export type IAreasGeoByBusinesses = Array<IAreasGeoByBusiness>;

export type IFetchAreasGeoByBusinessResponse = Promise<IAreasGeoByBusinesses>;
