var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.css()},[_c('DeliveryAreasHeader',{class:_vm.css('header'),attrs:{"business-id":_vm.businessId,"outlet-id":_vm.outletId,"has-listing-areas":_vm.hasListingAreas,"has-attached-areas":_vm.hasAreasListedFirstLoad,"on-display-add-modal":_vm.onDisplayAddModal}}),(_vm.hasAreasListedFirstLoad)?[_c('div',{class:_vm.css('stats')},[_c('AtomText',{attrs:{"type":_vm.AtomTextTypeEnum.H3}},[_vm._v(" "+_vm._s(_vm.tc("module.delivery_areas.listing.area_count", _vm.totalRecords))+" ")]),_c('div',{class:_vm.css('controls')},[(_vm.bulkSelectedIds.length)?_c('AtomButton',{class:_vm.css('controls-btn'),attrs:{"data-test":"btn-bulk-edit","size":_vm.AtomButtonSizeEnum.LARGE,"type":_vm.AtomButtonTypeEnum.SECONDARY,"on-click":function () {
              _vm.setBulkEditingModalData();
              _vm.showBulkEditingModal = true;
            }}},[_c('AtomIcon',{attrs:{"name":"pencil","size":21}}),_vm._v(" "+_vm._s(_vm.t("module.delivery_areas.listing.edit"))+" ("+_vm._s(_vm.bulkSelectedIds.length)+") ")],1):_vm._e(),(_vm.bulkSelectedIds.length)?_c('MolDeleteButton',{attrs:{"data-test":"btn-bulk-delete","on-click":function () { return (_vm.showBulkDeleteModal = true); }}},[_vm._v(" "+_vm._s(_vm.t("module.delivery_areas.listing.delete"))+" ("+_vm._s(_vm.bulkSelectedIds.length)+") ")]):_vm._e(),_c('MolSearchBox',{attrs:{"autofocus":"","on-input":_vm.debouncedOnChangeAreaSearch,"placeholder":_vm.t('module.delivery_areas.listing.search_by_area')}})],1)],1),_c('OrgTable',{attrs:{"columns":_vm.tableColumnsConfig,"loading":_vm.loadingAreas,"bulk-selection-ids":_vm.bulkSelectedIds,"on-bulk-selection-change":function (value) { return (_vm.bulkSelectedIds = value); },"data":_vm.areas,"has-actions":"","has-bulk-selection":"","on-sort":_vm.onSort,"no-records-msg":_vm.t('module.delivery_areas.table.no_match'),"total-records":_vm.areas.length,"row-click":function (row) { return _vm.goToEditor(row); }},scopedSlots:_vm._u([{key:"status",fn:function(ref){
            var cell = ref.cell;
            var row = ref.row;
return [(row.hasPolygon)?_c('AtomSwitcher',{attrs:{"checked":cell,"on-change":function (state) {
              _vm.toggleAreaStatus(row, state);
            }}}):_c('MolInfoLock',{class:_vm.css('lock')},[_vm._v(" "+_vm._s(_vm.t("module.delivery_areas.geolocation.no_polygons"))+" ")])]}},{key:"areaName",fn:function(ref){
            var cell = ref.cell;
            var row = ref.row;
return [_c('AtomText',{attrs:{"color":_vm.lineColor(row.status),"type":_vm.AtomTextTypeEnum.BODY_BOLD}},[_vm._v(" "+_vm._s(cell)+" ")])]}},{key:"city",fn:function(ref){
            var cell = ref.cell;
            var row = ref.row;
return [_c('AtomText',{attrs:{"color":_vm.lineColor(row.status),"type":_vm.AtomTextTypeEnum.BODY_BOLD}},[_vm._v(" "+_vm._s(cell)+" ")])]}},{key:"minBasket",fn:function(ref){
            var cell = ref.cell;
            var row = ref.row;
return [_c('AtomText',{class:_vm.css('cel-money'),attrs:{"color":_vm.lineColor(row.status)}},[_c('AtomMoney',{attrs:{"amount":cell.value,"currency":cell.currency}})],1)]}},{key:"deliveryFee",fn:function(ref){
            var cell = ref.cell;
            var row = ref.row;
return [_c('AtomText',{class:_vm.css('cel-money'),attrs:{"color":_vm.lineColor(row.status)}},[_c('AtomMoney',{attrs:{"amount":cell.value,"currency":cell.currency}})],1)]}},{key:"actions",fn:function(ref){
            var row = ref.row;
return [_c('MolTableAction',{attrs:{"icon-name":"delete","type":_vm.MolTableActionTypeEnum.DANGER,"on-click":function () { return (_vm.deleteModalData = { area: row.areaName, areaId: row.id }); }}})]}}],null,false,1395849348)})]:_vm._e(),(_vm.deleteModalData)?_c('MolModalConfirm',{attrs:{"title":_vm.t('module.delivery_areas.delete_modal.are_you_sure', {
        areaName: _vm.deleteModalData.area,
      }),"label":_vm.t('module.delivery_areas.delete_modal.desc'),"confirm-button-label":_vm.t('module.delivery_areas.delete_modal.delete_area'),"on-click-cancel":function () { return (_vm.deleteModalData = null); },"on-click-confirm":_vm.deleteArea}}):_vm._e(),(_vm.showBulkEditingModal && _vm.editingModalData)?_c('DeliveryAreasEditModal',{attrs:{"area-name":_vm.editingModalData.areaName,"areas-to-edit":_vm.editingModalData.id.length,"delivery-fee":_vm.editingModalData.deliveryFee,"min-basket-value":_vm.editingModalData.minBasketValue,"currency-code":_vm.currencyCode,"on-save":_vm.editAreaBulk,"on-close":function () {
        _vm.showBulkEditingModal = false;
        _vm.editingModalData = null;
      }}}):_vm._e(),(_vm.showBulkDeleteModal)?_c('MolModalConfirm',{attrs:{"title":_vm.tc(
        'module.delivery_areas.delete_modal.are_you_sure_bulk',
        _vm.bulkSelectedIds.length
      ),"label":_vm.t('module.delivery_areas.delete_modal.desc_bulk'),"confirm-button-label":_vm.t('module.delivery_areas.delete_modal.delete_area_bulk'),"on-click-cancel":function () { return (_vm.showBulkDeleteModal = false); },"on-click-confirm":_vm.deleteAreaBulk}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }