import { graphql } from "../graphql";
import mutation from "./mutation.gql";
import { RepoErrorEnum } from "../repo-error.enum";

export async function updateOutletAreaStatus(
  areaId: string,
  outletId: string,
  status: boolean
): Promise<boolean> {
  try {
    const response = await graphql.mutate({
      mutation,
      variables: {
        areaId,
        outletId,
        status,
      },
    });

    return response.data.updateAreaCoverageStatus;
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
}
