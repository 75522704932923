import { graphql } from "../graphql";
import query from "./query.gql";
import { RepoErrorEnum } from "../repo-error.enum";

export async function fetchOutletAddressGeolocation(
  outletId: string
): Promise<[number, number] | null> {
  try {
    const response = await graphql.query({
      query,
      variables: {
        outletId,
      },
    });

    const address = response?.data?.outlet?.address;

    if (address && address.lat) {
      return [address.lat, address.lng];
    }

    return null;
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
}
