import { graphql } from "../graphql";
import mutation from "./mutation.gql";
import { RepoErrorEnum } from "../repo-error.enum";

interface UpdateOutletAddress {
  outletId: string;
  addressId: string;
  area: string;
  location: string;
  lat: number;
  lng: number;
}

export async function updateOutletAddress({
  outletId,
  addressId,
  area,
  location,
  lat,
  lng,
}: UpdateOutletAddress): Promise<boolean> {
  try {
    const response = await graphql.mutate({
      mutation,
      variables: {
        outletId,
        addressId,
        area,
        location,
        lat,
        lng,
      },
    });

    return response.data.editOutletAddress;
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
}
