


















































































/* global google */
import { t } from "@/i18n";
import {
  ref,
  computed,
  onMounted,
  defineComponent,
  getCurrentInstance,
  onBeforeUnmount,
} from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import { useGoogleMaps } from "@/v2/util/maps/google-maps";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { useHelpers } from "@/v2/composable/use-helpers";
import { Toast } from "@/design-system";
import {
  AtomMap,
  AtomMapMarker,
  AtomMapPolygon,
  IAtomMapProps,
  MolMapAutocomplete,
  IMolMapAutocompletePrediction,
  MolModalConfirm,
} from "@/v2/new-design-system";
import { fetchOutletAddressGeolocation } from "@/v2/repo/fetch-outlet-address-geolocation";
import {
  createAreaGeoOnOulet,
  CreateAreaGeoOnOuletError,
  ICreateAreaGeoOnOuletBody,
} from "@/v2/repo/create-area-geo-on-outlet";
import {
  fetchAreasGeoByBusiness,
  IAreasGeoByBusiness,
  IAreasGeoByBusinesses,
} from "@/v2/repo/fetch-areas-geo-by-business";
import {
  DeliveryAreasGeoAreaInfoForm,
  IDeliveryAreasGeoAreaInfoFormData,
} from "../area-info-form";
import {
  IUpdateAreaGeoOnOuletBody,
  updateAreaGeoOnOulet,
  UpdateAreaGeoOnOuletError,
} from "@/v2/repo/update-area-geo-on-outlet";
import { deleteAreasOnOutlet } from "@/v2/repo/delete-areas-on-outlet";
import { globalConfig } from "@/v2/core";
import { deliveryAreasTrack } from "@/v2/module/delivery-areas/track";
import { EventEnum } from "@/v2/enum";
import { DeliveryAreasGeoConfirmOutletAddress } from "@/v2/module/delivery-areas/geo/confirm-outlet-address";
import { polygonSelfIntersection } from "@/v2/util/maps/polygon-self-intersection";
import { unWatchFlag, watchFlag } from "@/v2/core/feature-flag";

const apiKey = globalConfig.googleMapsApiKey;

const mapSettings = {
  centerMap: false,
  options: {
    disableDefaultUI: true,
    gestureHandling: "greedy",
  },
};
const polygonCommon = {
  strokeWeight: 1,
  strokeOpacity: 0.4,
  fillOpacity: 0.4,
};
const polygonInactiveSettings = {
  ...polygonCommon,
  strokeColor: "#838DA6", // --color-rainy
  fillColor: "#838DA6", // --color-rainy
};
const polygonActiveSettings = {
  ...polygonCommon,
  strokeColor: "#1258FF", // --color-chatfood
  fillColor: "#1258FF", // --color-chatfood
  editable: true,
  draggable: true,
};

const css = bemBuilder("delivery-areas-geo-map");

export default defineComponent({
  name: "DeliveryAreasGeoMap",
  components: {
    AtomMap,
    AtomMapMarker,
    MolModalConfirm,
    AtomMapPolygon,
    MolMapAutocomplete,
    DeliveryAreasGeoAreaInfoForm,
    DeliveryAreasGeoConfirmOutletAddress,
  },
  props: {
    areaId: {
      type: String,
      default: "",
    },
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const mapPosition = ref<IAtomMapProps["initialPosition"]>({});
    const outletPosition = ref<google.maps.LatLngLiteral | null>(null);
    const currentPolygon = ref<Array<[number, number]>>([]);
    const defaultPolygon = ref<Array<[number, number]>>([]);
    const allAreas = ref<IAreasGeoByBusinesses | null>(null);
    const editableArea = ref<IAreasGeoByBusiness>();
    const areaValidationErrors = ref<Record<string, string>>({});
    const isSubmitting = ref(false);
    const isMounting = ref(false);
    const currentInstance = getCurrentInstance();
    const showDeleteModal = ref(false);
    const addressExist = ref(true);

    const showAreaName = ref(false);
    const updateAreaNameFlag = (val: boolean) => (showAreaName.value = val);

    async function mountMap() {
      isMounting.value = true;

      try {
        let [allAreasResult, addressResult] = await Promise.all([
          fetchAreasGeoByBusiness(props.businessId),
          fetchOutletAddressGeolocation(props.outletId),
        ]);

        addressExist.value = Boolean(addressResult);

        if (addressResult) {
          const [lat, lng] = addressResult;
          outletPosition.value = { lat, lng };

          const defaultCoordinates = await getDefaultCoordinates(
            lat,
            lng,
            1000
          );

          // If editable area don't have a location, creating default polygons
          allAreas.value = allAreasResult.map((area) =>
            area.id === props.areaId && !area.location.length
              ? { ...area, location: defaultCoordinates }
              : area
          );

          if (props.areaId && allAreas.value) {
            editableArea.value = allAreas.value.find(
              (area) => area.id === props.areaId
            );
          }

          defaultPolygon.value = editableArea.value
            ? editableArea.value.location
            : defaultCoordinates;

          currentPolygon.value = defaultPolygon.value;
        }

        isMounting.value = false;
      } catch (e) {
        generalErrorToast();
      }
    }

    function onChangePoints(coordinates: Array<[number, number]>) {
      currentPolygon.value = coordinates;
    }

    function goToListingPage() {
      currentInstance?.proxy.$router.push({
        name: "outlets.delivery-areas",
        params: {
          businessId: props.businessId,
          outletId: props.outletId,
        },
      });
    }

    async function onDeleteArea(): Promise<void> {
      try {
        if (editableArea.value) {
          await deleteAreasOnOutlet([editableArea.value?.id], props.outletId);

          goToListingPage();

          new Toast().create({
            type: "success",
            title: t("module.delivery_areas.toast.deleted"),
            text: t("module.delivery_areas.toast.is_now_deleted", {
              areaName: editableArea.value?.areaName,
            }),
          });

          eventTrack(EventEnum.DELIVERY_AREA_DELETE);
        }
      } catch {
        generalErrorToast();
      } finally {
        showDeleteModal.value = false;
      }
    }

    async function onSave(data: IDeliveryAreasGeoAreaInfoFormData) {
      const isIntersected = polygonSelfIntersection(currentPolygon.value);

      if (isIntersected) {
        new Toast().create({
          type: "error",
          title: t("label.oops"),
          text: t("module.delivery_areas.geolocation.lines_are_overlapping"),
        });

        return;
      }

      isSubmitting.value = true;
      areaValidationErrors.value = {};

      const basePayload = {
        outletId: props.outletId,
        areaName: data.areaName,
        minBasket: data.minBasket,
        deliveryFee: data.deliveryFee,
        polygon: currentPolygon.value,
      };

      if (editableArea.value) {
        await updateArea({
          ...basePayload,
          areaId: props.areaId,
        });
      } else {
        await createArea(basePayload);
      }

      isSubmitting.value = false;
    }

    async function createArea(payload: ICreateAreaGeoOnOuletBody) {
      try {
        await createAreaGeoOnOulet(payload);

        new Toast().create({
          type: "success",
          title: t("module.delivery_areas.toast.created.title"),
          text: t("module.delivery_areas.toast.created.text"),
        });

        eventTrack(EventEnum.DELIVERY_AREA_CREATED);

        redirectToListingPage();
      } catch (e) {
        if (e instanceof CreateAreaGeoOnOuletError) {
          handleRepoError(e);
        } else {
          generalErrorToast();
        }
      }
    }

    async function updateArea(payload: IUpdateAreaGeoOnOuletBody) {
      try {
        await updateAreaGeoOnOulet(payload);

        new Toast().create({
          type: "success",
          title: t("module.delivery_areas.toast.updated.title"),
          text: t("module.delivery_areas.toast.updated.text"),
        });

        eventTrack(EventEnum.DELIVERY_AREA_UPDATED);

        redirectToListingPage();
      } catch (e) {
        if (e instanceof UpdateAreaGeoOnOuletError) {
          handleRepoError(e);
        } else {
          generalErrorToast();
        }
      }
    }

    function handleRepoError(
      error: CreateAreaGeoOnOuletError | UpdateAreaGeoOnOuletError
    ) {
      if (error.errors.validationError) {
        areaValidationErrors.value = error.errors.validationError;
        return;
      }

      generalErrorToast();
    }

    function setMapPosition(selected: IMolMapAutocompletePrediction) {
      mapPosition.value = {
        ...mapPosition.value,
        placeId: selected?.placeId,
      };
    }

    const country = computed(() => {
      const currentBusiness = useHelpers().getCurrentBusiness(props.businessId);
      return currentBusiness?.country;
    });

    const currency = computed(() => {
      const currentBusiness = useHelpers().getCurrentBusiness(props.businessId);
      return currentBusiness?.currency.code;
    });

    async function getDefaultCoordinates(
      lat: number,
      lng: number,
      distance: number
    ): Promise<Array<[number, number]>> {
      const googleMaps = await useGoogleMaps(apiKey).getMaps();

      const center = new googleMaps.LatLng({ lat, lng });
      const size = new googleMaps.Size(distance, distance);

      const computeOffset = googleMaps?.geometry.spherical.computeOffset;

      const north = computeOffset(center, size.height / 2, 0).lat();
      const south = computeOffset(center, size.height / 2, 180).lat();
      const east = computeOffset(center, size.width / 2, 90).lng();
      const west = computeOffset(center, size.width / 2, 270).lng();

      if (!west || !north || !east || !south) return [];

      return [
        [north, west],
        [north, east],
        [south, east],
        [south, west],
      ];
    }

    function redirectToListingPage() {
      context.root.$router.push({
        name: "outlets.delivery-areas",
        params: {
          businessId: props.businessId,
          outletId: props.outletId,
        },
      });
    }

    function redirectToAddressPage() {
      context.root.$router.push({
        name: "outlets.address",
        params: {
          businessId: props.businessId,
          outletId: props.outletId,
        },
      });
    }

    const vm = getCurrentInstance();

    function eventTrack(eventName: EventEnum) {
      deliveryAreasTrack({
        eventName,
        // @ts-ignore
        analytics: vm?.proxy.$analytics,
        businessId: props.businessId,
        outletId: props.outletId,
        additionalData: {
          delivery_area_type: "polygon",
        },
      });
    }

    function showPolygonDetails(id: string): boolean {
      return showAreaName.value && !isEditablePolygon(id);
    }

    function isEditablePolygon(id: string): boolean {
      return Boolean(editableArea.value && editableArea.value.id === id);
    }

    const polygonTitle = (area: IAreasGeoByBusiness): string => {
      return showPolygonDetails(area.id) ? area.areaName : "";
    };

    onMounted(() => {
      watchFlag("area-name", updateAreaNameFlag);
      mountMap();
    });

    onBeforeUnmount(() => {
      unWatchFlag("area-name", updateAreaNameFlag);
    });

    return {
      t,
      css,
      apiKey,
      country,
      currency,
      mapPosition,
      showDeleteModal,
      mountMap,
      onDeleteArea,
      outletPosition,
      onChangePoints,
      currentPolygon,
      defaultPolygon,
      editableArea,
      areaValidationErrors,
      allAreas,
      isSubmitting,
      isMounting,
      addressExist,
      showAreaName,
      onSave,
      setMapPosition,
      redirectToAddressPage,
      isEditablePolygon,
      polygonTitle,
      showPolygonDetails,
      mapSettings,
      polygonActiveSettings,
      polygonInactiveSettings,
    };
  },
});
