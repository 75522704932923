import { graphql } from "../graphql";
import mutation from "./mutation.gql";
import { RepoErrorEnum } from "../repo-error.enum";

export async function deleteAreasOnOutlet(
  areaIds: Array<string>,
  outletId: string
): Promise<boolean> {
  try {
    const response = await graphql.mutate({
      mutation,
      variables: {
        areaIds,
        outletId,
      },
    });

    return response.data.deleteAreaCoverages;
  } catch {
    throw Error(RepoErrorEnum.NETWORK_ERROR);
  }
}
