

































































































































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  getCurrentInstance,
  PropType,
  ref,
} from "@vue/composition-api";
import {
  AtomIcon,
  AtomMoney,
  AtomSwitcher,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomButton,
  AtomButtonTypeEnum,
  AtomButtonSizeEnum,
  MolInfoLock,
  MolSearchBox,
  MolModalConfirm,
  MolDeleteButton,
  MolTableAction,
  MolTableActionTypeEnum,
  OrgTable,
  IOrgTableProps,
} from "@/v2/new-design-system";
import { Toast } from "@/design-system";
import { DeliveryAreasHeader } from "../header";
import { DeliveryAreasEditModal } from "../edit-modal";
import { deleteAreasOnOutlet } from "@/v2/repo/delete-areas-on-outlet";
import { updateAreasOnOutlet } from "@/v2/repo/update-areas-on-outlet";
import { updateOutletAreaStatus } from "@/v2/repo/update-outlet-area-status";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { deliveryAreasTrack } from "@/v2/module/delivery-areas/track";
import { CurrencyCodeEnum, EventEnum } from "@/v2/enum";
import { IDeliveryAreasArea, IDeliveryAreasSort } from "../types";
import { debounce } from "@/v2/util/debounce";
import { useRouter } from "@/router";
import { tc, t } from "@/i18n";

const css = bemBuilder("delivery-areas-listing");

const tableColumnsConfig: IOrgTableProps["columns"] = {
  status: {
    header: () => t("module.delivery_areas.table.status"),
    show: true,
    sortable: true,
    width: 90,
    sortDirection: "NONE",
  },
  areaName: {
    header: () => t("module.delivery_areas.table.area"),
    show: true,
    sortable: true,
    width: 250,
    sortDirection: "ASC",
  },
  city: {
    header: () => t("module.delivery_areas.table.city"),
    show: true,
    sortable: false,
    width: 120,
    sortDirection: "NONE",
  },
  minBasket: {
    header: () => t("module.delivery_areas.table.minBasket"),
    helpText: () => t("module.delivery_areas.min_basket_help"),
    show: true,
    sortable: true,
    width: 150,
    sortDirection: "NONE",
    align: "right",
  },
  deliveryFee: {
    header: () => t("module.delivery_areas.table.deliveryFee"),
    show: true,
    sortable: true,
    width: 120,
    sortDirection: "NONE",
    align: "right",
  },
};

export default defineComponent({
  name: "DeliveryAreasListing",
  components: {
    AtomIcon,
    AtomText,
    AtomMoney,
    AtomButton,
    AtomSwitcher,
    MolInfoLock,
    MolSearchBox,
    MolTableAction,
    MolDeleteButton,
    MolModalConfirm,
    OrgTable,
    DeliveryAreasHeader,
    DeliveryAreasEditModal,
  },
  props: {
    areas: {
      type: Array as PropType<IDeliveryAreasArea[]>,
      required: true,
    },
    loadingAreas: {
      type: Boolean,
      required: false,
    },
    outletId: {
      type: String,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
    currencyCode: {
      type: String as PropType<CurrencyCodeEnum>,
      required: true,
    },
    hasListingAreas: {
      type: Boolean,
      default: false,
    },
    hasAreasListedFirstLoad: {
      type: Boolean,
      default: false,
    },
    totalRecords: {
      type: Number,
      default: 0,
    },
    onSearch: {
      type: Function as PropType<(query: string) => void>,
      required: true,
    },
    onSort: {
      type: Function as PropType<
        (
          field: IDeliveryAreasSort["field"],
          direction: IDeliveryAreasSort["direction"]
        ) => void
      >,
      required: true,
    },
    onDisplayAddModal: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onDeleteArea: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onUpdateArea: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const bulkSelectedIds = ref<Array<string>>([]);
    const showBulkDeleteModal = ref(false);
    const showBulkEditingModal = ref(false);
    const deleteModalData = ref<{ areaId: string; area: string } | null>(null);
    const editingModalData = ref<{
      id: Array<string>;
      areaName: string;
      minBasketValue: number;
      deliveryFee: number;
      currencyCode: CurrencyCodeEnum;
    } | null>(null);

    async function toggleAreaStatus(
      area: IDeliveryAreasArea,
      newStatus: boolean
    ) {
      try {
        await updateOutletAreaStatus(area.id, props.outletId, newStatus);

        props.onUpdateArea();

        const textParams = { areaName: area.areaName };
        new Toast().create({
          type: "success",
          title: newStatus
            ? t("module.delivery_areas.toast.enabled")
            : t("module.delivery_areas.toast.disabled"),
          text: newStatus
            ? t("module.delivery_areas.toast.is_now_enabled", textParams)
            : t("module.delivery_areas.toast.is_now_disabled", textParams),
        });

        eventTrack(EventEnum.DELIVERY_AREA_CHANGED_STATE, {
          status: newStatus ? "active" : "paused",
        });
      } catch {
        props.onUpdateArea();
        generalErrorToast();
      }
    }

    const debouncedOnChangeAreaSearch = debounce((query: string) => {
      props.onSearch(query.toLowerCase().trim());
    }, 250);

    async function deleteArea() {
      if (!deleteModalData.value) return;

      try {
        await deleteAreasOnOutlet(
          [deleteModalData.value.areaId],
          props.outletId
        );
        props.onDeleteArea();

        new Toast().create({
          type: "success",
          title: t("module.delivery_areas.toast.deleted"),
          text: t("module.delivery_areas.toast.is_now_deleted", {
            areaName: deleteModalData.value.area,
          }),
        });

        eventTrack(EventEnum.DELIVERY_AREA_DELETE);
      } catch {
        generalErrorToast();
      } finally {
        deleteModalData.value = null;
      }
    }

    const router = useRouter();

    function goToEditor(area: IDeliveryAreasArea) {
      if (area.isShared) {
        editingModalData.value = {
          id: [area.id],
          areaName: area.areaName,
          minBasketValue: area.minBasket.value,
          deliveryFee: area.deliveryFee.value,
          currencyCode: props.currencyCode,
        };

        showBulkEditingModal.value = true;
        return;
      }

      router.push({
        name: "outlets.delivery-areas.edit",
        params: {
          businessId: props.businessId,
          outletId: props.outletId,
          areaId: area.id,
        },
      });
    }

    function setBulkEditingModalData() {
      const areasToEdit = props.areas.filter((area) =>
        bulkSelectedIds.value.includes(area.id)
      );

      const hasDistinctMinBasketValues =
        new Set(areasToEdit.map((area) => area.minBasket)).size > 1;
      const hasDistinctDeliveryFees =
        new Set(areasToEdit.map((area) => area.deliveryFee)).size > 1;

      editingModalData.value = {
        id: bulkSelectedIds.value,
        areaName: areasToEdit.length === 1 ? areasToEdit[0].areaName : "",
        minBasketValue: hasDistinctMinBasketValues
          ? 0
          : areasToEdit[0].minBasket.value,
        deliveryFee: hasDistinctDeliveryFees
          ? 0
          : areasToEdit[0].deliveryFee.value,
        currencyCode: props.currencyCode,
      };
    }

    async function deleteAreaBulk() {
      if (!bulkSelectedIds.value.length) return;

      try {
        await deleteAreasOnOutlet(bulkSelectedIds.value, props.outletId);
        props.onDeleteArea();

        new Toast().create({
          type: "success",
          title: t("module.delivery_areas.toast.deleted_bulk"),
          text: tc(
            "module.delivery_areas.toast.is_now_deleted_bulk",
            bulkSelectedIds.value.length
          ),
        });

        eventTrack(EventEnum.DELIVERY_AREA_BULK_DELETE, {
          action_type: bulkSelectedIds.value.length > 1 ? "bulk" : "single",
        });
      } catch {
        generalErrorToast();
      } finally {
        showBulkDeleteModal.value = false;
        bulkSelectedIds.value = [];
      }
    }

    async function editAreaBulk(minBasket: number, deliveryFee: number) {
      if (!editingModalData.value) return;

      try {
        await updateAreasOnOutlet(
          editingModalData.value.id,
          props.outletId,
          minBasket,
          deliveryFee
        );

        props.onUpdateArea();

        new Toast().create({
          type: "success",
          title: t("module.delivery_areas.toast.update_bulk"),
          text: tc(
            "module.delivery_areas.toast.is_now_updated_bulk",
            bulkSelectedIds.value.length
          ),
        });

        eventTrack(EventEnum.DELIVERY_AREA_BULK_EDIT, {
          action_type: editingModalData.value.id.length > 1 ? "bulk" : "single",
        });

        showBulkEditingModal.value = false;
      } catch {
        generalErrorToast();
      } finally {
        editingModalData.value = null;
        bulkSelectedIds.value = [];
      }
    }

    function goToCreate() {
      router.push({
        name: "outlets.delivery-areas.create",
        params: {
          businessId: props.businessId,
          outletId: props.outletId,
        },
      });
    }

    function lineColor(status: boolean) {
      return status ? AtomTextColorEnum.MIDNIGHT : AtomTextColorEnum.RAINY;
    }

    const currentInstance = getCurrentInstance();

    function eventTrack(
      eventName: EventEnum,
      additionalData: Record<string, any> = {}
    ): void {
      deliveryAreasTrack({
        // @ts-ignore
        analytics: currentInstance?.proxy.$analytics,
        eventName,
        businessId: props.businessId,
        outletId: props.outletId,
        additionalData: {
          delivery_area_type: "polygon",
          ...additionalData,
        },
      });
    }

    return {
      t,
      tc,
      css,
      lineColor,
      deleteArea,
      toggleAreaStatus,
      deleteModalData,
      editingModalData,
      goToEditor,
      goToCreate,
      showBulkDeleteModal,
      showBulkEditingModal,
      debouncedOnChangeAreaSearch,
      bulkSelectedIds,
      AtomButtonTypeEnum,
      AtomButtonSizeEnum,
      setBulkEditingModalData,
      deleteAreaBulk,
      editAreaBulk,
      eventTrack,
      tableColumnsConfig,
      EventEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      MolTableActionTypeEnum,
    };
  },
});
