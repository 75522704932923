



























































































import { t } from "@/i18n";
import { CurrencyCodeEnum } from "@/v2/enum";
import {
  AtomButton,
  AtomText,
  AtomTextTypeEnum,
  MolBackNav,
  MolDeleteButton,
  MolInfoBulb,
  OrgFormInput,
  OrgFormMoneyInput,
} from "@/v2/new-design-system";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import { IDeliveryAreasGeoAreaInfoFormProps } from "../area-info-form/props";

const css = bemBuilder("delivery-areas-geo-area-info-form");

export default defineComponent({
  name: "DeliveryAreasGeoAreaInfoForm",
  components: {
    OrgFormInput,
    AtomButton,
    MolInfoBulb,
    AtomText,
    OrgFormMoneyInput,
    MolBackNav,
    MolDeleteButton,
  },
  props: {
    initialValues: {
      type: Object as PropType<{
        areaName: string;
        minBasket: number;
        deliveryFee: number;
      }>,
      default: () => ({}),
    },
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    validationErrors: {
      type: Object as PropType<Record<string, string>>,
      default: () => ({}),
    },
    currency: {
      type: String as PropType<CurrencyCodeEnum>,
      default: CurrencyCodeEnum.AED,
    },
    onClickDelete: {
      type: Function as PropType<() => void>,
      default: () => () => {},
    },
    onSave: {
      type: Function as PropType<IDeliveryAreasGeoAreaInfoFormProps["onSave"]>,
      required: true,
    },
  },
  setup(props) {
    const areaName = ref(props.initialValues.areaName || "");
    const minBasket = ref(props.initialValues.minBasket || 0);
    const deliveryFee = ref(props.initialValues.deliveryFee || 0);
    const areaNameErrorMessage = ref("");

    const areaHeader = computed(() =>
      props.initialValues.areaName
        ? t("module.delivery_areas.geolocation.area_info_form.edit_area_header")
        : t(
            "module.delivery_areas.geolocation.area_info_form.create_area_header"
          )
    );

    const buttonLabel = computed(() =>
      props.initialValues.areaName
        ? t("module.delivery_areas.geolocation.area_info_form.save_changes")
        : t("module.delivery_areas.geolocation.area_info_form.create_area_cta")
    );

    const formValidation = (): void => {
      areaNameErrorMessage.value = "";

      if (!areaName.value.trim()) {
        areaNameErrorMessage.value = t(
          "module.delivery_areas.geolocation.area_info_form.area_name_required"
        );
        return;
      }

      if (areaName.value.length < 3) {
        areaNameErrorMessage.value = t(
          "module.delivery_areas.geolocation.area_info_form.area_name_too_short"
        );
        return;
      }

      if (areaName.value.length > 100) {
        areaNameErrorMessage.value = t(
          "module.delivery_areas.geolocation.area_info_form.area_name_too_long"
        );
      }
    };

    watch(
      () => props.validationErrors,
      () => {
        if (props.validationErrors.areaName) {
          areaNameErrorMessage.value = props.validationErrors.areaName;
        }
      },
      {
        deep: true,
      }
    );

    function onClickCta() {
      formValidation();

      if (areaNameErrorMessage.value) return;

      props.onSave({
        areaName: areaName.value,
        minBasket: minBasket.value,
        deliveryFee: deliveryFee.value,
      });
    }

    return {
      t,
      areaHeader,
      buttonLabel,
      areaName,
      minBasket,
      deliveryFee,
      onClickCta,
      areaNameErrorMessage,
      css,
      AtomTextTypeEnum,
    };
  },
});
