import { AxiosResponse } from "axios";
import { IRepoErrors } from "../../errors";
import { http } from "../../http";

interface IFetchFoodicsBranchesRequest {
  businessId: string;
}

export interface IFetchFoodicsBranchesResponse {
  name: string;
  branchId: string;
  linkedOutletId: string;
}

interface IDataResponse {
  name: string;
  branch_id: string;
  linked_outlet_id: string;
}

export class FetchFoodicsBranchesError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchFoodicsBranches");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchFoodicsBranches(
  request: IFetchFoodicsBranchesRequest
): Promise<Array<IFetchFoodicsBranchesResponse>> {
  let response: AxiosResponse;

  try {
    response = await http.get(`/foodics/${request.businessId}/branches`);
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchFoodicsBranchesError(error);
  }

  return response.data.data
    ? response.data.data.map((obj: IDataResponse) => ({
        name: obj.name,
        branchId: obj.branch_id,
        linkedOutletId: obj.linked_outlet_id,
      }))
    : [];
}
