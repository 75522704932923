import { graphql } from "../graphql";
import query from "./query.gql";
import { RepoErrorEnum } from "../repo-error.enum";

export async function fetchOutletAddress(
  outletId: string
): Promise<IFetchOutletAddress> {
  try {
    const response = await graphql.query({
      query,
      variables: {
        outletId,
      },
    });

    return response?.data?.outlet;
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
}

export interface IFetchOutletAddress {
  id: string;
  address: {
    id: string;
    lat: number;
    lng: number;
    location: string;
    nickname: string;
    area: {
      name: string;
    };
  };
  business: {
    country: string;
  };
}
