












































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, ref, computed } from "@vue/composition-api";
import {
  AtomModal,
  AtomText,
  AtomTextTypeEnum,
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  OrgFormMoneyInput,
} from "@/v2/new-design-system";
import { CurrencyCodeEnum } from "@/v2/enum";
import { t, tc } from "@/i18n";

const css = bemBuilder("delivery-areas-edit-modal");

export default defineComponent({
  name: "DeliveryAreasEditModal",
  components: {
    AtomModal,
    AtomText,
    AtomButton,
    OrgFormMoneyInput,
  },
  props: {
    areaName: {
      type: String,
      required: true,
    },
    areasToEdit: {
      type: Number,
      required: true,
    },
    currencyCode: {
      type: String as PropType<CurrencyCodeEnum>,
      required: true,
    },
    minBasketValue: {
      type: Number,
      required: true,
    },
    deliveryFee: {
      type: Number,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    onSave: {
      type: Function as PropType<
        (minBasketValue: number, deliveryFee: number) => Promise<void>
      >,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(false);

    const minBasketValueRaw = ref(props.minBasketValue);
    const deliveryFeeRaw = ref(props.deliveryFee);

    const heading = computed(() =>
      props.areasToEdit === 1
        ? t("module.delivery_areas.edit_modal.heading_area_name", {
            areaName: props.areaName,
          })
        : tc("module.delivery_areas.edit_modal.heading_areas", [
            props.areasToEdit,
          ])
    );

    async function save() {
      isLoading.value = true;
      await props.onSave(minBasketValueRaw.value, deliveryFeeRaw.value);
      isLoading.value = false;
    }

    return {
      t,
      css,
      save,
      heading,
      isLoading,
      minBasketValueRaw,
      deliveryFeeRaw,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
      AtomTextTypeEnum,
    };
  },
});
